import type { FC, ReactNode } from 'react';
import React, { useState, Fragment } from 'react';
import { styled } from '@compiled/react';

import { N0, N20, N40, N50, N60, N500, N800 } from '@atlaskit/theme/colors';
import type { TooltipPrimitiveProps } from '@atlaskit/tooltip';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';
import AkChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import AkChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import type { TriggerProps } from '@atlaskit/tooltip/types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { SpotlightTarget } from '@atlaskit/onboarding';

import { fontFamily } from '@confluence/typography-placeholder';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { handleClickSpaceSideNavHeader } from './collapsibleSectionUtils';

// Needed for un-nesting afterIconButton from the header button for better accessibility
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderAreaGrid = styled.div({
	display: 'grid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:hover > :first-child': {
		backgroundColor: token('color.background.neutral.subtle.hovered', N20),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CollapsibleSectionHeader = styled.button({
	display: 'flex',
	gridRow: 1,
	gridColumn: 1,
	height: '36px',
	width: '100%',
	backgroundColor: token('elevation.surface', N0),
	cursor: 'pointer',
	alignItems: 'center',
	position: 'relative',
	fontWeight: 700,
	fontSize: '11px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontFamily,
	color: `${token('color.text', N500)}`,
	textTransform: 'uppercase',
	padding: `0px ${token('space.050', '4px')} 0px ${token('space.100', '8px')}`,
	border: 'none',
	borderRadius: '3px',
	'&:hover': {
		backgroundColor: `${token('color.background.neutral.subtle.hovered', N20)}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NewFeaturesCollapsibleSectionHeader = styled.button({
	display: 'flex',
	gridRow: 1,
	gridColumn: 1,
	height: '36px',
	width: '100%',
	backgroundColor: token('elevation.surface', N0),
	alignItems: 'center',
	position: 'relative',
	fontWeight: 400,
	fontSize: '14px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontFamily,
	color: `${token('color.text', N500)}`,
	padding: `0px ${token('space.050', '4px')} 0px 0px`,
	border: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CollapsibleSectionWrapper = styled.div<{ isOpen: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'#page-tree-spinner': {
		minHeight: '0px',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: ({ isOpen }) => (isOpen ? '18px' : '0'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FadeInContainer = styled.div<{ isOpen: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isOpen }) => (isOpen ? '100%' : '0%'),
	transition: 'opacity 0.5s ease',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AfterIconButtonWrapper = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	gridRow: 1,
	gridColumn: 1,
	margin: `${token('space.075', '6px')} ${token(
		'space.050',
		'4px',
	)} 0px ${token('space.050', '4px')}`,
	height: '24px',
	width: '24px',
	justifySelf: 'end',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
	"button:not([data-testid='space-views-menu-group'] button, button[data-testid='create-menu-link-item'], button[data-testid='create-menu-button-item'])":
		{
			position: 'relative',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			height: '24px !important',
			width: '24px',
			minHeight: '24px',
			minWidth: '24px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'#space-apps-collapsible-section-header-button': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		span: {
			height: '16px',
			width: '16px',
			color: `${token('color.icon.accent.gray', N50)}`,
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&:hover span': {
			color: `${token('color.icon', N500)}`,
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WiderTooltip = styled<TooltipPrimitiveProps>(TooltipPrimitive)({
	maxWidth: '300px',
	background: `${token('color.background.neutral.bold', N800)}`,
	color: `${token('color.text.inverse', N0)}`,
	borderRadius: '3px',
	padding: '2px 6px',
	fontSize: '12px',
	lineHeight: '20px',
});

const beforeIconColor = token('color.icon.accent.gray', '#626f86');
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BeforeIconBorder = styled.div({
	position: 'absolute',
	border: `1px solid ${token('color.border', N40)}`,
	borderRadius: '4px',
	width: '22px',
	height: '22px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionTitle = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginLeft: token('space.100', '8px'),
	color: `${token('color.text.accent.gray', '#626f86')}`,
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

export const EmptySidebarCollapsibleSectionContainerWrapper = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	return (
		<EmptySidebarCollapsibleSectionContainer
			data-vc="empty-sidebar-collapsible-section-container"
			{...ssrPlaceholderIdProp}
		>
			{children}
		</EmptySidebarCollapsibleSectionContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptySidebarCollapsibleSectionContainer = styled.div({
	marginLeft: token('space.150', '12px'),
	color: `${token('color.text.subtlest', N60)}`,
});

const BeforeIcon = ({ isOpen, showBorder = true }: { isOpen: boolean; showBorder?: boolean }) => (
	<Fragment>
		{showBorder && <BeforeIconBorder />}
		{isOpen ? (
			<AkChevronDownIcon label="" primaryColor={beforeIconColor} />
		) : (
			<AkChevronRightIcon label="" primaryColor={beforeIconColor} />
		)}
	</Fragment>
);

type CollapsibleHeaderProps = {
	renderAfterIconButton: (
		setHeaderExpandedState: ((newIsOpen: boolean) => void) | undefined,
	) => ReactNode;
	headerTitle: string;
	isOpen: boolean;
	onClick: () => void;
	tooltipProps?: TriggerProps;
	setHeaderExpandedState?: (newIsOpen: boolean) => void;
};

const CollapsibleHeader: FC<CollapsibleHeaderProps> = ({
	renderAfterIconButton,
	headerTitle,
	isOpen,
	onClick,
	tooltipProps,
	setHeaderExpandedState,
}) => (
	<HeaderAreaGrid>
		<CollapsibleSectionHeader
			{...tooltipProps}
			onClick={() => onClick()}
			id={headerTitle}
			aria-expanded={isOpen}
			aria-controls={headerTitle}
			data-testId={headerTitle}
		>
			<BeforeIcon isOpen={isOpen} />
			<SectionTitle>{headerTitle}</SectionTitle>
			<FadeInContainer isOpen={isOpen} />
		</CollapsibleSectionHeader>

		<AfterIconButtonWrapper>{renderAfterIconButton(setHeaderExpandedState)}</AfterIconButtonWrapper>
	</HeaderAreaGrid>
);

type NewFeaturesCollapsibleHeaderProps = {
	headerTitle: string;
	isOpen: boolean;
	onClick: () => void;
	tooltipProps?: TriggerProps;
};

const NewFeaturesCollapsibleHeader: FC<NewFeaturesCollapsibleHeaderProps> = ({
	headerTitle,
	isOpen,
	onClick,
	tooltipProps,
}) => (
	<HeaderAreaGrid>
		<NewFeaturesCollapsibleSectionHeader
			{...tooltipProps}
			onClick={() => onClick()}
			id={headerTitle}
			aria-expanded={isOpen}
			aria-controls={headerTitle}
		>
			<BeforeIcon isOpen={isOpen} showBorder={false} />
			<SectionTitle>{headerTitle}</SectionTitle>
			<FadeInContainer isOpen={isOpen} />
		</NewFeaturesCollapsibleSectionHeader>
	</HeaderAreaGrid>
);

type CollapsibleSectionProps = {
	renderAfterIconButton: (
		setHeaderExpandedState: ((newIsOpen: boolean) => void) | undefined,
	) => ReactNode;
	children: React.ReactNode;
	headerTitle: string;
	initialExpandedState?: boolean;
	onClick?: (isOpen: boolean) => void;
	updateExpandedState?: (isOpen: boolean) => void;
	tooltipContent?: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

export const CollapsibleSection: FC<CollapsibleSectionProps> = ({
	renderAfterIconButton,
	children,
	headerTitle,
	initialExpandedState = true,
	onClick = () => {},
	updateExpandedState,
	tooltipContent,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const [isOpen, setIsOpen] = useState<boolean>(initialExpandedState);

	const setHeaderExpandedState = (newIsOpen: boolean) => {
		if (newIsOpen !== isOpen) {
			updateExpandedState?.(newIsOpen);
			setIsOpen(newIsOpen);
		}
	};

	return (
		<CollapsibleSectionWrapper
			data-vc="collapsible-section-wrapper"
			{...ssrPlaceholderIdProp}
			isOpen={isOpen}
		>
			<SpotlightTarget name="sideNavCollapsibleSectionSpotlight">
				{tooltipContent === undefined ? (
					<CollapsibleHeader
						renderAfterIconButton={renderAfterIconButton}
						headerTitle={headerTitle}
						isOpen={isOpen}
						setHeaderExpandedState={setHeaderExpandedState}
						onClick={() => {
							onClick(isOpen);
							setIsOpen((prevState) => !prevState);
						}}
					/>
				) : (
					<Tooltip
						position="top"
						component={WiderTooltip}
						content={<TooltipContainer>{tooltipContent}</TooltipContainer>}
					>
						{(tooltipProps) => (
							<CollapsibleHeader
								tooltipProps={tooltipProps}
								renderAfterIconButton={renderAfterIconButton}
								headerTitle={headerTitle}
								isOpen={isOpen}
								setHeaderExpandedState={setHeaderExpandedState}
								onClick={() => {
									onClick(isOpen);
									setIsOpen((prevState) => !prevState);
								}}
							/>
						)}
					</Tooltip>
				)}
			</SpotlightTarget>
			<FadeInContainer isOpen={isOpen} id={headerTitle} role="region" aria-labelledby={headerTitle}>
				{isOpen && children}
			</FadeInContainer>
		</CollapsibleSectionWrapper>
	);
};

type AdvancedFeaturesCollapsibleSectionProps = {
	isAdvancedFeaturesOpen?: boolean;
};

export const AdvancedFeaturesCollapsibleSection: FC<
	CollapsibleSectionProps & AdvancedFeaturesCollapsibleSectionProps
> = ({
	renderAfterIconButton,
	children,
	headerTitle,
	tooltipContent,
	isAdvancedFeaturesOpen,
	onClick = () => {},
}) => {
	const isOpen = Boolean(isAdvancedFeaturesOpen);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<CollapsibleSectionWrapper isOpen={isOpen}>
			{tooltipContent === undefined ? (
				<CollapsibleHeader
					renderAfterIconButton={renderAfterIconButton}
					headerTitle={headerTitle}
					isOpen={isOpen}
					onClick={() => {
						onClick(isOpen);
						handleClickSpaceSideNavHeader(createAnalyticsEvent, headerTitle, isOpen);
					}}
				/>
			) : (
				<Tooltip
					position="top"
					component={WiderTooltip}
					content={<TooltipContainer>{tooltipContent}</TooltipContainer>}
				>
					{(tooltipProps) => (
						<CollapsibleHeader
							tooltipProps={tooltipProps}
							renderAfterIconButton={renderAfterIconButton}
							headerTitle={headerTitle}
							onClick={() => {
								onClick(isOpen);
								handleClickSpaceSideNavHeader(createAnalyticsEvent, headerTitle, isOpen);
							}}
							isOpen={Boolean(isOpen)}
						/>
					)}
				</Tooltip>
			)}

			<FadeInContainer
				isOpen={Boolean(isOpen)}
				id={headerTitle}
				role="region"
				aria-labelledby={headerTitle}
			>
				{isOpen && children}
			</FadeInContainer>
		</CollapsibleSectionWrapper>
	);
};

type NewFeaturesCollapsibleSectionProps = {
	headerTitle: string;
	tooltipContent?: string;
};

export const NewFeaturesCollapsibleSection: FC<NewFeaturesCollapsibleSectionProps> = ({
	headerTitle,
	tooltipContent,
	children,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<CollapsibleSectionWrapper isOpen={isOpen}>
			<Tooltip
				position="top"
				component={WiderTooltip}
				content={<TooltipContainer>{tooltipContent}</TooltipContainer>}
			>
				{(tooltipProps) => (
					<NewFeaturesCollapsibleHeader
						tooltipProps={tooltipProps}
						headerTitle={headerTitle}
						onClick={() => {
							setIsOpen(!isOpen);
						}}
						isOpen={isOpen}
					/>
				)}
			</Tooltip>
			<FadeInContainer isOpen={isOpen} id={headerTitle} role="region" aria-labelledby={headerTitle}>
				{isOpen && children}
			</FadeInContainer>
		</CollapsibleSectionWrapper>
	);
};
