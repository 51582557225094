import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import {
	confluenceSessionStorageInstance as sessionStorage,
	keys as sessionStorageKeys,
} from '@confluence/storage-manager';

export const handleClickSpaceSideNavHeader = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	sectionName: string,
	isOpen: boolean,
) => {
	updateExpandedState({
		isOpen: !isOpen,
		sectionKey: sectionName,
		sessionStorageKey: sessionStorageKeys.SPACE_NAV_SECTIONS_EXPANDED_STATES,
	});
	createAnalyticsEvent({
		type: 'sendUIEvent',
		data: {
			action: !isOpen ? 'opened' : 'closed',
			actionSubject: 'collapsibleSectionHeader',
			source: 'sideNavigation',
			attributes: {
				section: sectionName,
			},
		},
	}).fire();
};

type updateExpandedStateArgs = {
	isOpen: boolean;
	sectionKey: string;
	sessionStorageKey: string;
};

export const updateExpandedState = ({
	isOpen,
	sectionKey,
	sessionStorageKey,
}: updateExpandedStateArgs) => {
	const expandedStates = sessionStorage.getItem(sessionStorageKey);
	if (expandedStates) {
		expandedStates[sectionKey] = isOpen;
		sessionStorage.setItem(sessionStorageKey, {
			...expandedStates,
		});
	}
};
