import type { FC, ReactNode } from 'react';
import React from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	confluenceSessionStorageInstance as sessionStorage,
	keys as sessionStorageKeys,
} from '@confluence/storage-manager';

import { CollapsibleSection } from './CollapsibleSection';
import { handleClickSpaceSideNavHeader, updateExpandedState } from './collapsibleSectionUtils';

const getInitialExpandedStateBySpace = (sectionName: string, spaceKey: string) => {
	if (sessionStorage.doesContain(sessionStorageKeys.SPACE_NAV_SECTIONS_EXPANDED_STATES)) {
		const sideNavSectionStates = sessionStorage.getItem(
			sessionStorageKeys.SPACE_NAV_SECTIONS_EXPANDED_STATES,
		);
		if (spaceKey === sideNavSectionStates.spaceKey) {
			return sideNavSectionStates[sectionName];
		}
	}
	return true;
};

const getInitialExpandedStateByStorageKey = (
	sectionName: string,
	sessionStorageKey: string,
	defaultExpanded?: boolean,
) => {
	if (sessionStorage.doesContain(sessionStorageKey)) {
		const sideNavSectionStates = sessionStorage.getItem(sessionStorageKey);
		return sideNavSectionStates[sectionName] ?? defaultExpanded;
	}
	return defaultExpanded ?? true;
};

type PersistentCollapsibleSectionProps = {
	renderAfterIconButton: (
		setHeaderExpandedState: ((newIsOpen: boolean) => void) | undefined,
	) => ReactNode;
	children: ReactNode;
	defaultIsExpanded: boolean;
	headerTitle: string;
	onClick?: (isOpen: boolean) => void;
	sectionName: string;
	sessionStorageKey: string;
	tooltipContent?: string;
};

type SpacePersistentCollapsibleSectionProps = Omit<
	PersistentCollapsibleSectionProps,
	'sessionStorageKey' | 'defaultIsExpanded'
> & {
	spaceKey: string;
};

export const PersistentCollapsibleSection: FC<PersistentCollapsibleSectionProps> = ({
	renderAfterIconButton,
	children,
	defaultIsExpanded,
	headerTitle,
	onClick = () => {},
	sectionName,
	sessionStorageKey,
	tooltipContent,
}) => {
	return (
		<CollapsibleSection
			renderAfterIconButton={renderAfterIconButton}
			headerTitle={headerTitle}
			initialExpandedState={getInitialExpandedStateByStorageKey(
				sectionName,
				sessionStorageKey,
				defaultIsExpanded,
			)}
			onClick={(isOpen) => {
				onClick(isOpen);
				updateExpandedState({
					isOpen: !isOpen,
					sectionKey: sectionName,
					sessionStorageKey,
				});
			}}
			tooltipContent={tooltipContent}
		>
			{children}
		</CollapsibleSection>
	);
};

export const SpacePersistentCollapsibleSection: FC<SpacePersistentCollapsibleSectionProps> = ({
	renderAfterIconButton,
	children,
	headerTitle,
	sectionName,
	spaceKey,
	tooltipContent,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return (
		<CollapsibleSection
			renderAfterIconButton={renderAfterIconButton}
			headerTitle={headerTitle}
			initialExpandedState={getInitialExpandedStateBySpace(sectionName, spaceKey)}
			updateExpandedState={(isOpen) =>
				updateExpandedState({
					isOpen,
					sectionKey: sectionName,
					sessionStorageKey: sessionStorageKeys.SPACE_NAV_SECTIONS_EXPANDED_STATES,
				})
			}
			onClick={(isOpen) => handleClickSpaceSideNavHeader(createAnalyticsEvent, sectionName, isOpen)}
			tooltipContent={tooltipContent}
		>
			{children}
		</CollapsibleSection>
	);
};
